import React from "react";

import spotify from "../img/social/spotify.svg";
import soundcloud from "../img/social/soundcloud.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="socials">
          <a href="https://open.spotify.com/artist/58P0gfQki9SlBEyCpn3wP8">
            <img src={spotify} />
          </a>
          <a href="https://soundcloud.com/wintercolor">
            <img src={soundcloud} />
          </a>
          <a href="https://twitter.com/wintercolor_">
            <img src={twitter} />
          </a>
          <a href="https://instagram.com/wintercolor_">
            <img src={instagram} />
          </a>
        </div>
      </footer>
    );
  }
};

export default Footer;
