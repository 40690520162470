import React from "react";
import { Link } from "gatsby";
import header from "../img/header.jpg";
import logo from "../img/logo.png";

const Navbar = class extends React.Component {
  render() {
    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0)), url(${header})`,
        }}
      >
        <div id="navMenu">
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/nie">
              home
            </Link>
            <Link className="navbar-item" to="/commissions">
              commissions
            </Link>
            <Link className="navbar-item" to="/releases">
              releases
            </Link>
          </div>
        </div>
        <div className="navlogoc">
          <img src={logo} className="navlogo" style={{ height: "70px" }} />
        </div>
      </nav>
    );
  }
};

export default Navbar;
